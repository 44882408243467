<template>
  <div id="app" :class="vueAppClasses" @contextmenu.prevent>
    <div class="grain"></div>
		<router-view />
    <!-- <CookieBanner @cookies-accepted="initializeKakaoSdk"/> -->
    <Intro />
	</div>
</template>

<script>
import Intro from '@/views/pages/web/intro/SplashScreen.vue'
import CookieBanner from '@/layouts/official/banners/CookieBanner.vue'
import jwt from "@/http/requests/auth/jwt/index.js"
export default {
  data() {
    return {
      stuckAnchor: [0, 0, 0, 0, 0, 0],
      parallaxRateLarge: 15,
      parallaxRateSmall: 5,
      parallaxY: [],
      parallaxReverseY: [],
      timerId: null,
      board: {
        title: 'DCOD',
        keyword: '웹사이트, 반응형 디자인, 랜딩페이지, erp, crm, 홈피, 홈페이지, 웹에이전시, si, it기업, Web Agency'
      }
    }
  },
  metaInfo() {
    return {
      title: 'DCOD',
      titleTemplate: '%s | We Code, You Excel™',
      meta: [
          {
            property: 'og:title',
            content: 'DCOD',
            template: chunk => `${chunk} | www.yellowhippy.kr`,
            vmid: 'og:title'
          }
      ]
    }
  },
  components: {
    Intro,
    CookieBanner
  },
  computed: {
    isSplash() {
      return this.$store.state.isSplash
    },
    isMobile() {
      return this.$store.state.isMobile
    },
    vueAppClasses() {
      var storage = localStorage.getItem('theme')
      return storage ? storage : 'darkmode'
    },
    weight() {
      return this.$store.state.weight
    },
    scrollY() {
      return this.$store.state.scrollY
    },
    innerWidth() {
      return this.$store.state.innerWidth
    },
    innerHeight() {
      return this.$store.state.innerHeight
    },
  },
  watch: {
    scrollY() {
      this.initAnimation()
    },
  },
  methods: {
    init() {
      this.aosText()
      this.slideUpElement()
      this.setupParallaxImgController()
      this.toggleParallaxImgScale()
    },
    initAnimation() {
      this.aosText()
      this.slideUpElement()
      this.toggleParallaxImg();
      this.toggleParallaxImgScale()
      this.toggleStickyArea()
      // this.toggleStuck()
    },
    aosText() {
      var el = document.querySelectorAll('.aos-content')
      el.forEach((item, index) => {
        var rect = item.getBoundingClientRect()
        if(rect.top > this.innerHeight) {
          for(var i=0; i<item.children.length; i++) {
            item.children[i].classList.add('aos-content--inner-inactive')
          }
        } else {
          for(var i=0; i<item.children.length; i++) {
            item.children[i].classList.remove('aos-content--inner-inactive')
          }
        }
      })
    },
    slideUpElement() {
      return new Promise((resolve, reject) => {
        var el = document.querySelectorAll('.transition-slide')
        el.forEach((item, index) => {
          var rect = item.getBoundingClientRect()
          if(rect.top <= this.innerHeight) {
            item.classList.remove('transition-slide--hide')
            item.classList.add('transition-slide--show')
          } else {
            item.classList.remove('transition-slide--show')
            item.classList.add('transition-slide--hide')
          }
        })
        resolve()
      })
    },
    setupParallaxImgController() {
      this.parallaxY = Array(document.querySelectorAll('.parallax').length).fill(-this.parallaxRateSmall)
      this.parallaxReverseY = Array(document.querySelectorAll('.parallax_reverse').length).fill(-this.parallaxRateSmall)
    },
    toggleParallaxImg() {
        var el = document.querySelectorAll('.parallax');
        var elReverse = document.querySelectorAll('.parallax_reverse');
        el.forEach((item, index) => {
          var rect = item.getBoundingClientRect();
          var triggerPoint = this.innerHeight;
          if ((rect.top <= (triggerPoint)) && rect.top > -(rect.height)) {
              var percent = Math.round((-this.parallaxRateSmall + ((triggerPoint - rect.top) / triggerPoint) * (this.parallaxRateSmall * 2)) * 100) / 100;
              this.$set(this.parallaxY, index, percent);
              item.children[0].style.setProperty('transform', `translate3d(0, ${this.parallaxY[index]}%, 0)`);
          }
        });
        elReverse.forEach((item, index) => {
          var rect = item.getBoundingClientRect();
          var triggerPoint = this.innerHeight * 0.75;
          if ((rect.top <= (triggerPoint)) && rect.top > -(rect.height)) {
              var percent = Math.round((this.parallaxRateSmall - ((triggerPoint - rect.top) / triggerPoint) * (this.parallaxRateSmall * 2)) * 100) / 100;
              this.$set(this.parallaxReverseY, index, percent * 10);
              item.children[0].style.setProperty('transform', `translate3d(0, ${this.parallaxReverseY[index]}%, 0)`);
          }
        });
    },
    toggleParallaxImgScale() {
        var wrap = document.querySelector('.parallax-scale--wrap');
        var trigger = document.querySelectorAll('.parallax-scale')[0];
        if(!trigger) return
        var itemWidth = wrap.getBoundingClientRect().width / 3
        var itemHeight = wrap.getBoundingClientRect().height
        var rect = trigger.getBoundingClientRect();

        var poligan = document.querySelectorAll('.poligan')
        var subtext = document.querySelector('.subtext')

        var el = document.querySelectorAll('.parallax-scale');
        el.forEach((item, index) => {
            var triggerPoint = this.isMobile ? this.innerWidth : this.innerHeight; // 뷰포트 높이 기준
            // percent 값 계산: 요소의 위치를 비율로 반영
            var x;
            var y;
            var scale;
            var percent = ((triggerPoint - rect.top) / triggerPoint) * this.parallaxRateLarge;

            switch (index) {
                case 0: // 첫 번째 요소: 작아짐
                  scale = (1.35 - (1.35 * (percent / 100))); // 작아지는 값
                  x = 0;
                  y = -(document.querySelectorAll(".headline")[0].clientHeight - (document.querySelectorAll(".headline")[0].children[0].clientHeight * 2)) - subtext.clientHeight;
                  break;

                case 1: // 두 번째 요소: 커짐
                  scale = (0.75 + (0.75 * (percent / 100))); // 커지는 값
                  x = (itemWidth * (1 - scale)) * 2; // 간격 유지 조정
                  y = ((itemHeight * (1 - scale)) * 2) - subtext.clientHeight; // y 값 조정
                  break;

                case 2: // 세 번째 요소: 커짐
                  scale = (0.75 + (0.75 * (percent / 100))); // 커지는 값
                  x = itemWidth * (1 - scale); // 간격 유지 조정
                  y = ((itemHeight * (1 - scale)) * 3) - subtext.clientHeight; // y 값 조정
                  break;
            }
            item.style.setProperty(
                'transform',
                `translate3d(${x}px, ${y}px, 0) scale(${scale})`
            );
        });
        poligan.forEach((item, index) => {
          var triggerPoint = this.isMobile ? this.innerWidth : this.innerHeight // 뷰포트 높이 기준
          var x

          var scale
          let distance = this.isMobile ?  1.5 : 1.42
          var percent = ((triggerPoint - rect.top) / triggerPoint) * this.parallaxRateLarge;
          switch (index) {
            case 0:
                scale = (1.5 - (percent / 75))
                item.style.setProperty(
                  'width',
                  `${(itemWidth * scale)}px`
                )
              break;
            case 1:
                scale = (distance - (percent / 50))
                x = (itemWidth / 4) - (percent * 2.5)
                item.style.setProperty(
                  'width',
                  `${(itemWidth * scale)}px`
                )
                // subtext[index]?.style.setProperty(
                //   'transform',
                //   `translate(${x}px, 0px)`
                // )
              break;
          }
        })
    },
    toggleStickyArea() {
      const path = this.$route.path;
      const stickyAreas = document.querySelectorAll('.sticky_area');
      const stickyItems = document.querySelectorAll('.sticky_items');
      
      // 공통 조건: sticky_area가 화면 밖으로 나간 경우 종료
      if(!stickyAreas.length) return
      if (this.stickyAreasOutOfView(stickyAreas)) return;

        let isVisible = false;
        
        // '/home' 경로 처리
        if (path === '/home') {
          isVisible = this.isStickyAreaVisible(stickyAreas, stickyItems);
          this.handleHomePath(isVisible, stickyItems);
        
        // '/services' 경로 처리
        } else if (path === '/services') {
          isVisible = this.isStickyAreaFromCenter(stickyAreas, stickyItems);
          this.handleServicesPath(isVisible, stickyItems);
        
        // 그 외 경로 처리
        } else {
          isVisible = this.isStickyAreaFromCenter(stickyAreas, stickyItems);
          this.updateStickyState(isVisible);
          if (isVisible) this.moveStickyArea();
        }
    },
    toggleStuck() { 
      if (this.handleStuckArea()) {
        if (!this.stopScroll) {
          this.stopScroll = true;
          this.$store.commit("TOGGLE_SCROLL_STOP_STATE", true);
          this.$store.commit("SET_IS_STUCK", true);
          const stockArea = document.querySelector(".stockArea");
          const rect = stockArea.getBoundingClientRect().top;
          setTimeout(() => {
              document.querySelector(".scrollArea").style.setProperty("transform", `matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, ${this.scrollY - rect}, 0, 1)`);
          }, 1);
        } 
        return;
      } else {
        this.stopScroll = false;
        this.$store.commit("TOGGLE_SCROLL_STOP_STATE", false);
        this.$store.commit("SET_IS_STUCK", false);
      }
    },
    handleStuckArea() {
      const stockArea = document.querySelector('.stockArea');
      if (stockArea) {
        const rect = stockArea.getBoundingClientRect();

        const isInViewport = rect.top <= window.innerHeight && rect.bottom >= window.innerHeight;

        const direction = Math.sign(this.deltaY);
        
        const topView = (rect.top <= 10 && (direction === 1));
        const bottomView = (rect.bottom > 0 && (direction === -1));
        const isEndOfCount = (this.permitCount == 0 && (direction === -1)) || (this.permitCount == (this.$businessTokens.images.businessList.length - 1) && (direction === 1))
        // console.log(!isEndOfCount && isInViewport && (topView || bottomView))
        if (!isEndOfCount && isInViewport && (topView || bottomView)) {
          return true;
        } else {
          return false;
        }
      }
    },
    stickyAreasOutOfView(stickyAreas) {
      return (
        stickyAreas[0].getBoundingClientRect().top >= this.innerHeight &&
        stickyAreas[1].getBoundingClientRect().top >= this.innerHeight
      );
    },
    isStickyAreaVisible(stickyAreas, stickyItems) {
      let isVisible = false;
      stickyAreas.forEach((item, index) => {
        const rect = item.getBoundingClientRect();
        const isActive = rect.bottom >= window.innerHeight && rect.top <= 0;
        stickyItems[index].classList.toggle('sticky_items--active', isActive);
        if (isActive) isVisible = true;
      });
      return isVisible;
    },
    isStickyAreaFromCenter(stickyAreas, stickyItems) {
      let isVisible = false;
      stickyAreas.forEach((item, index) => {
        const rect = item.getBoundingClientRect();
        const target = stickyItems[index];
        const isActive = this.checkCenterVisibility(rect, target, item, index);
        target.classList.toggle('sticky_items--active', isActive);
        if (isActive) isVisible = true;
      });
      return isVisible;
    },
    checkCenterVisibility(rect, target, item, index) {
      if (item.classList.contains('until_bottom')) {
        const threshold = this.isMobile ? 0.55 : 0.5
        const topLimit = window.innerHeight;
        return (rect.bottom >= window.innerHeight * threshold + target.clientHeight) && (rect.top <= topLimit);
      } else if (item.classList.contains('until_top')) {
        const topLimit = (window.innerHeight * 0.3)
        return rect.top <= (topLimit * -1)
      } else {
        return rect.bottom >= window.innerHeight && rect.top <= 0;
      }
    },
    handleHomePath(isVisible, stickyItems) {
      if (isVisible) {
        this.updateStickyState(true);
        const index = this.getActiveStickyItemIndex(stickyItems);
        if (index !== -1) {
          this.setRelativeHeightForHome(index);
          this.moveStickyArea();
        }
      } else {
        this.updateStickyState(false);
      }
    },
    handleServicesPath(isVisible, stickyItems) {
      if (isVisible) {
        this.updateStickyState(true);
        const index = this.getActiveStickyItemIndex(stickyItems)
        if (index !== -1) {
          this.setRelativeHeightForServices(index);
          this.moveStickyArea();
        }
      } else {
        this.updateStickyState(false);
      }
    },
    updateStickyState(isVisible) {
      if (isVisible) {
        if (!this.isStickyArea) {
          this.$store.commit('SET_IS_STICKY', true);
        }
      } else {
        this.$store.commit('SET_IS_STICKY', false);
      }
    },
    getActiveStickyItemIndex(stickyItems) {
      return [...stickyItems].findLastIndex(item => item.classList.contains('sticky_items--active'));
    },
    setRelativeHeightForHome(index) {
      const sections = ['#section1', '#section2', '#section3'];
      if (index === 1) {
        sections.push('#section4', '#section5', '#section6', '#section7');
      }
      this.stuckAnchor = sections.reduce((sum, section) => sum + document.querySelector(section).clientHeight, 0);
    },
    setRelativeHeightForServices(index) {
      var threshold;
      if(index < 2) {
        threshold = 0
      } else {
        threshold = index - 1
      }
      this.$set(this.stuckAnchor, index, (window.innerHeight * 0.3) + (window.innerHeight * (index + threshold)))
    },
    moveStickyArea() {
      const activeItem = document.querySelectorAll('.sticky_items--active');
      activeItem.forEach((item, index) => {
        const offsetY = (Math.abs(this.scrollY) - this.stuckAnchor[index])
        item.style.transform = `translate3d(0, ${Math.abs(offsetY)}px, 0)`;
      })
      if ((this.$route.path == '/home')) {
        this.moveHorizontal();
      }
    },
    moveHorizontal() {
      const rect = document.querySelectorAll('.sticky_area')[0].getBoundingClientRect();
      const stickyTop = Math.sign(this.deltaY) === -1 ? (Math.abs(rect.top) < 12 ? 0 : Math.abs(rect.top)) : Math.abs(rect.top);
      document.querySelector('.hl_slide__inner').style.transform = `translate3d(-${stickyTop * 1.5}px, 0, 0)`;
    },
    initializeKakaoSdk() {
      if (window.Kakao && !window.Kakao.isInitialized()) {
        try {
          window.Kakao.init('a00045c4b49c7f9be84e8b976e1af322'); // 여기에 실제 앱 키를 넣어주세요
        } catch (error) {
          console.error('Kakao SDK 초기화 오류:', error);
        }
      }
    },
    updateHtmlClass() {
      const width = window.innerWidth;
      let classNames = [];
      if (width >= 2560) {
        classNames.push('w2560');
      } else if (width >= 1920) {
        classNames.push('w1920');
      } else if (width >= 1600) {
        classNames.push('w1600');
      } else if (width >= 1600) {
        classNames.push('w1280');
      } else if (width >= 1280) {
        classNames.push('w1280');
      } else if (width >= 1024) {
        classNames.push('w1024');
      } else if (width >= 768) {
        classNames.push('w768');
      } else if (width >= 600) {
        classNames.push('w600');
      } else {
        classNames.push('w390');
      }
      document.documentElement.className = classNames.join(' ');
    },
    updateSize() {
      const { innerHeight } = window;
      document.documentElement.style.setProperty("--app-height",`${innerHeight}px`);
      this.$store.commit('SET_INNER_WIDTH', window.innerWidth * this.weight)
      this.$store.commit('SET_INNER_HEIGHT', innerHeight)
      this.$store.commit('SET_IS_MOBILE', innerHeight)
      this.updateHtmlClass()
    },
    closeRightNav(e) {
      const hoveredElement = document.elementFromPoint(e.clientX, e.clientY);
      var isHoveringButton = hoveredElement?.closest(".rightNav")
      if(isHoveringButton != document.querySelector(".rightNav")) {
        this.$store.commit('TOGGLE_IS_OPEN_RIGHTNAV', false)
      }
    },
    clearTimer() {
      clearTimeout(this.timerId);
      this.timerId = null;
    }
  },
  async created() {
    window.addEventListener("DOMContentLoaded", (ev) => {
      this.updateSize()
      this.init()
    });
  },
  mounted() {
    setTimeout(() => {
    }, 100);
    window.addEventListener('resize', this.updateSize);
    window.addEventListener('click', this.closeRightNav);
    this.initAnimation()
  },
  beforeDestroy() {
    window.onload = null
    this.clearTimer()
    window.removeEventListener('resize', this.updateSize);
  },
}
</script>