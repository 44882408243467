import { render, staticRenderFns } from "./Marquee.vue?vue&type=template&id=131e2cc0&scoped=true"
import script from "./Marquee.vue?vue&type=script&lang=js"
export * from "./Marquee.vue?vue&type=script&lang=js"
import style0 from "./Marquee.vue?vue&type=style&index=0&id=131e2cc0&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "131e2cc0",
  null
  
)

export default component.exports